import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import './style.css';


export const Social = () => (
  <div>
    <a
      className="icon-style"
      href="https://www.instagram.com/tumult.live/"
      rel="noreferrer"
      target="_blank"
      title="instagram"
    >
      <FaInstagram />
    </a>
    <a
      className="icon-style"
      href="https://www.youtube.com/channel/UCFfgXUx0a1Pteh2sD_dp8WA"
      rel="noreferrer"
      target="_blank"
      title="youtube"
    >
      <FaYoutube />
    </a>
    <a
      className="icon-style"
      href="https://facebook.com/tumult.live.be"
      rel="noreferrer"
      target="_blank"
      title="facebook"
    >
      <FaFacebookF />
    </a>
  </div>
);
