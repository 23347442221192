import { Social } from "components";
import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          href="https://facebook.com/tumult.live.be"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="App-header--content">
            <span className="title">TUMULT</span>
            <span className="subtitle">live</span>
            <div className="App-link">FOLLOW</div>
          </div>
        </a>
        <div style={{ marginTop: 40 }}>
          <Social />
        </div>
      </header>
    </div>
  );
}

export default App;
